import {
  BsShop,
  // BsPeople,
  // BsArchive,
  // BsPersonX,
  BsBarChartLine,
} from 'react-icons/bs';
// import { AiOutlineAppstoreAdd, AiOutlineNotification } from 'react-icons/ai';
// import { GrUserAdmin } from 'react-icons/gr';

export const adminRoutes = [
  {
    name: 'Statistics',
    href: '/',
    icon: <BsBarChartLine className="text-xl text-gray-800" />,
  },
  {
    name: 'Products',
    href: '/products',
    icon: <BsShop className="text-xl text-gray-800" />,
  },
  // {
  //   name: 'Brukere',
  //   href: '/users',
  //   icon: <BsPeople className="text-xl text-gray-800" />,
  // },
  // {
  //   name: 'Rapporterte brukere',
  //   href: '/reported-users',
  //   icon: <BsPersonX className="text-xl text-gray-800" />,
  // },
  // {
  //   name: 'Rapporterte annonser',
  //   href: '/reported-products',
  //   icon: <BsArchive className="text-xl text-gray-800" />,
  // },
  // {
  //   name: 'Send pushvarsel',
  //   href: '/push-notification',
  //   icon: <AiOutlineNotification className="text-xl text-gray-800" />,
  // },
  // {
  //   name: 'Administrator annonser',
  //   href: '/products/admin-products',
  //   icon: <GrUserAdmin className="text-xl text-gray-800" />,
  // },
  // {
  //   name: 'Brukerannonser',
  //   href: '/products/users-products',
  //   icon: <AiOutlineAppstoreAdd className="text-xl text-gray-800" />,
  // },
];
