import { useState } from 'react';
// import { BiArrowBack } from 'react-icons/bi';
import { HiMenu } from 'react-icons/hi';
import { adminRoutes } from './routes';
import Sidebar from './Sidebar';
import { HiOutlineXCircle } from 'react-icons/hi';

const DashboardLayout = ({ children, pageTitle, backRoute }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const router = useRouter();

  return (
    <div>
      <Sidebar
        routes={adminRoutes}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <div className="flex flex-col flex-1 md:pl-64">
        <div className="sticky top-0 z-10 pt-1 pl-1 bg-gray-100 sm:pl-3 sm:pt-3">
          <button
            type="button"
            className={`md:hidden -ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500`}
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            {sidebarOpen ? (
              <HiOutlineXCircle className="w-6 h-6" aria-hidden="true" />
            ) : (
              <HiMenu className="w-6 h-6" aria-hidden="true" />
            )}
          </button>
        </div>
        <main className="flex-1">
          <div className="py-6">
            {pageTitle && (
              <div className="px-4 pb-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">
                  {pageTitle}
                </h1>
              </div>
            )}
            <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
              {/* {backRoute && (
                <button
                  className="bg-[#F3BEA6] rounded px-2 py-1 text-gray-800 hover:bg-[#f3bea65f] hover:cursor-pointer"
                  onClick={() => router.back()}
                >
                  <div className="items-center justify-center mb-1 hover:cursor-pointer">
                    <BiArrowBack className="text-white text-xl" />
                  </div>
                </button>
              )} */}
            </div>
            <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
              <div className="py-4">{children}</div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
