import { useQuery } from 'react-query';
import { supabase } from '../service/supabase';

async function getStatistics() {
  let statistics = [];
  const { count, countError } = await supabase
    .from('products')
    .select('id', { count: 'exact' });

  let { data: productsByMarka, productsByMarkaError } = await supabase.rpc(
    'get_products_by_marka'
  );

  let { data: productsBySeller, productsBySellerError } = await supabase.rpc(
    'get_products_by_seller_type'
  );

  let {
    data: productsByFuelAndTransmission,
    productsByFuelAndTransmissionError,
  } = await supabase.rpc('get_products_by_fuel_and_transmission');

  let { data: productsByYear, productsByYearError } = await supabase.rpc(
    'get_model_year_distribution'
  );

  let { data: totalMarks, totalMarksError } = await supabase.rpc(
    'get_total_unique_markas'
  );

  let { data: byTargetMarket, targetMarketError } = await supabase.rpc(
    'get_product_count_by_target_market'
  );

  if (totalMarksError) {
    throw totalMarksError;
  }

  if (productsByMarkaError) {
    throw productsByMarkaError;
  }

  if (productsBySellerError) {
    throw productsBySellerError;
  }

  if (productsByFuelAndTransmissionError) {
    throw productsByFuelAndTransmissionError;
  }
  if (productsByYearError) {
    throw productsByYearError;
  }

  if (targetMarketError) {
    throw targetMarketError;
  }
  if (countError) {
    throw countError;
  }

  if (count) {
    statistics = [
      ...statistics,
      { title: 'Products Total Count', value: count, unit: 'Products' },
    ];
  }
  if (totalMarks) {
    statistics = [
      ...statistics,
      {
        title: 'Total Brands',
        value: totalMarks[0]?.total_unique_markas,
        unit: 'Brands',
      },
    ];
  }

  return {
    statistics,
    productsByMarka,
    productsBySeller,
    productsByFuelAndTransmission,
    productsByYear,
    byTargetMarket,
  };
}

export function useStatistics(page) {
  return useQuery(['statistics', page], () => getStatistics());
}
