import React from 'react'; // , { useEffect }
import DashboardLayout from './../components/layouts/Dashboard';
import { useStatistics } from '../hooks/useStatistics';
// import { supabase } from '../service/supabase';

const MarkasList = ({ data = [] }) => {
  return (
    <div>
      <h2 className="mt-12 text-xl ">By Brand</h2>
      <div className="mt-2 flex flex-col">
        <div className="inline-block min-w-full">
          <div className="overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Marka
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Max Price
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Min Price
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Average Price
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Min Year
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Max Year
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Products Count
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {data &&
                  data.map((product, productIndex) => (
                    <React.Fragment key={`${product.id}-${productIndex}`}>
                      <tr
                        className={
                          productIndex % 2 === 0 ? undefined : 'bg-gray-50'
                        }
                      >
                        <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.marka}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.max_price}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.min_price}
                        </td>

                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.avg_price}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.min_year}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.max_year}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.product_count}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const BySellersList = ({ data = [] }) => {
  return (
    <div>
      <h2 className="mt-12 text-xl ">By Seller Type</h2>
      <div className="mt-2 flex flex-col">
        <div className="inline-block min-w-full">
          <div className="overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Seller Type
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Products Count
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {data &&
                  data.map((product, productIndex) => (
                    <React.Fragment key={`${product.id}-${productIndex}`}>
                      <tr
                        className={
                          productIndex % 2 === 0 ? undefined : 'bg-gray-50'
                        }
                      >
                        <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.seller_type}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.product_count}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
const ByFuelAndTransmissionList = ({ data = [] }) => {
  return (
    <div>
      <h2 className="mt-12 text-xl ">By Fuel and Transmission Type</h2>
      <div className="mt-2 flex flex-col">
        <div className="inline-block min-w-full">
          <div className="overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Fuel Type
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Transmission Type
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Products Count
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {data &&
                  data.map((product, productIndex) => (
                    <React.Fragment key={`${product.id}-${productIndex}`}>
                      <tr
                        className={
                          productIndex % 2 === 0 ? undefined : 'bg-gray-50'
                        }
                      >
                        <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.fuel_type}
                        </td>
                        <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.transmission_type}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.product_count}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
const ByYearList = ({ data = [] }) => {
  return (
    <div>
      <h2 className="mt-12 text-xl ">By Fuel and Transmission Type</h2>
      <div className="mt-2 flex flex-col">
        <div className="inline-block min-w-full">
          <div className="overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Model Year
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Products Count
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {data &&
                  data.map((product, productIndex) => (
                    <React.Fragment key={`${product.id}-${productIndex}`}>
                      <tr
                        className={
                          productIndex % 2 === 0 ? undefined : 'bg-gray-50'
                        }
                      >
                        <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.model_year}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.product_count}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
const TargetMArketList = ({ data = [] }) => {
  return (
    <div>
      <h2 className="mt-12 text-xl ">By Target Market</h2>
      <div className="mt-2 flex flex-col">
        <div className="inline-block min-w-full">
          <div className="overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Target Market
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Products Count
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {data &&
                  data.map((product, productIndex) => (
                    <React.Fragment key={`${product.id}-${productIndex}`}>
                      <tr
                        className={
                          productIndex % 2 === 0 ? undefined : 'bg-gray-50'
                        }
                      >
                        <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.target_market || 'Unknown'}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.product_count}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const Statistics = () => {
  const { data: allStatistics } = useStatistics();

  // useEffect(() => {
  //   (async () => {
  //     let { data, error } = await supabase.rpc(
  //       'get_product_count_by_target_market'
  //     );
  //     if (error) console.error(error);
  //     else console.log(data);
  //   })();
  // }, []);
  return (
    <DashboardLayout pageTitle="Statistics">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
        {allStatistics &&
          allStatistics?.statistics?.map((card, index) => {
            return (
              <div key={index} className="rounded-lg shadow py-6 px-4">
                <h2 className="text-xl text-gray-500">{card.title}</h2>
                <p className="text-md text-black">
                  {card.value} {card.unit}
                </p>
              </div>
            );
          })}
      </div>

      <div>
        <TargetMArketList data={allStatistics?.byTargetMarket} />
      </div>
      <div>
        <MarkasList data={allStatistics?.productsByMarka} />
      </div>
      <div>
        <BySellersList data={allStatistics?.productsBySeller} />
      </div>

      <div>
        <ByFuelAndTransmissionList
          data={allStatistics?.productsByFuelAndTransmission}
        />
      </div>

      <div>
        <ByYearList data={allStatistics?.productsByYear} />
      </div>
    </DashboardLayout>
  );
};

export default Statistics;
