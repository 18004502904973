import React from 'react';
import { Link } from 'react-router-dom';
// import { BiUserCircle } from 'react-icons/bi';
// import { Logo } from '../icons';
// import { supabaseClient } from '@supabase/auth-helpers-nextjs';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Sidebar({ routes, sidebarOpen, setSidebarOpen }) {
  // const router = useRouter();

  return (
    <div>
      {/* <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex md:hidden"
          onClose={setSidebarOpen}
        >
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </TransitionChild>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col flex-1 w-full max-w-xs bg-gray-100">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 pt-2 -mr-12">
                  <button
                    type="button"
                    className="flex items-center justify-center w-10 h-10 ml-1 rounded-full bg-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <HiOutlineXCircle
                      className="w-6 h-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="relative flex align-center justify-center w-auto h-8 text-xl font-bold uppercase">
                  Istibana
                </div>
                <nav className="px-2 mt-12 space-y-1">
                  {routes.map((item) => (
                    <Link key={item.name} to={item.href}>
                      <a
                        onClick={() => setSidebarOpen(false)}
                        className={classNames(
                          'text-gray-800 hover:bg-[#f3bea65f] hover:cursor-pointer group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                        )}
                      >
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              // router.pathname === item.href
                              //   ? 'text-gray-300'
                              //   : 'text-gray-400 group-hover:text-gray-300',
                              // 'mr-3 flex-shrink-0 py-1 w-6'
                              'text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 py-1 w-6'
                            )}
                            aria-hidden="true"
                          >
                            {item.icon}
                          </span>
                          {item.name}
                        </div>
                      </a>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root> */}
      <div
        className={`${
          sidebarOpen ? '' : 'hidden'
        } md:flex md:w-64 md:flex-col md:fixed md:inset-y-0`}
      >
        <div className="flex flex-col flex-1 min-h-0 bg-gray-100">
          <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
            <div className="relative flex align-center justify-center w-auto h-16 text-xl font-bold uppercase">
              Istibana
            </div>
            <nav className="flex-1 px-2 mt-5 space-y-1">
              {routes.map((item) => (
                <Link className="no-underline" key={item.name} to={item.href}>
                  <div
                    className={classNames(
                      // router.pathname === item.href
                      //   ? 'bg-[#F3BEA6] text-gray-800'
                      //   : 'text-gray-800 hover:bg-[#f3bea65f] hover:cursor-pointer',
                      // 'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                      'text-gray-800 hover:bg-[#f3bea65f] hover:cursor-pointer group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <div className="flex items-center">
                      <span
                        className={classNames(
                          // router.pathname === item.href
                          //   ? 'text-gray-300'
                          //   : 'text-gray-400 group-hover:text-gray-300',
                          // 'mr-3 flex-shrink-0 py-1 w-6'

                          'text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 py-1 w-6'
                        )}
                        aria-hidden="true"
                      >
                        {item.icon}
                      </span>
                      {item.name}
                    </div>
                  </div>
                </Link>
              ))}
            </nav>
          </div>
          {/* <div className="flex flex-shrink-0 p-4 bg-gray-100">
            <div className="flex-shrink-0 block w-full group">
              <div className="flex items-center">
                <BiUserCircle className="text-gray-800" size={40} />
                <div className="ml-3">
                  <button
                    onClick={async () => {
                      try {
                        const { error } = await supabaseClient.auth.signOut();
                        if (!error) {
                          router.push('/login');
                        }
                      } catch (error) {
                        console.log('error :', error);
                      }
                    }}
                    className="text-xs font-medium text-gray-800 group-hover:text-gray-700"
                  >
                    Logg ut
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
