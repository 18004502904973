import React, { useState } from 'react';
import DashboardLayout from './../components/layouts/Dashboard';
import {
  useProducts,
  useBrandsList,
  useUniqModelsList,
} from '../hooks/useProducts';

export const ProductsList = () => {
  const [page, setPage] = useState(1);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedModel, setSelectedModel] = useState('');

  const { data: products } = useProducts(page, selectedBrand, selectedModel);
  const { data: brands } = useBrandsList();
  const { data: models } = useUniqModelsList(selectedBrand);

  // useEffect(() => {
  //   (async () => {
  //     const { data, error } = await supabase.rpc('get_filtered_products', {
  //       filter_brand: selectedBrand || null,
  //       filter_model: selectedModel || null,
  //       page_size: 500,
  //       page_number: 1,
  //     });

  //     console.log('error :', error);
  //     console.log('data :', data);
  //   })();
  // }, [selectedBrand, selectedModel]);

  const goToPreviousPage = () => setPage(page - 1);
  const goToNextPage = () => setPage(page + 1);

  const hasMore =
    products !== undefined && products !== null && !(500 > products.length);
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleExpandedRow = (index) => {
    setExpandedRows((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };
  return (
    <>
      <div>
        <div className="overflow-auto p-4 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <p className="text-lg text-gray-600">
            {selectedBrand !== '' && products && `Total Filtered Products:`}{' '}
            {selectedBrand !== '' && products && (
              <strong>{products?.length}</strong>
            )}{' '}
          </p>
          <div>
            <div>
              <label className={`text-sm text-gray-600`} htmlFor="brand">
                Select Brand
              </label>
            </div>
            <div>
              <select
                className="{`w-full p-2 mt-2 placeholder-gray-400 border focus:ring-slate-500 focus:border-slate-500  rounded-md shadow-sm focus:outline-none border-gray-300 text-sm"
                name="brand"
                id="brand"
                onChange={(e) => {
                  setSelectedBrand(e.target.value);
                  setSelectedModel('');
                }}
              >
                <option value="">Select Brand</option>
                {brands &&
                  brands.map(({ brand }, index) => (
                    <option key={index} value={brand}>
                      {brand}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="mt-4">
            <div>
              <label
                className={`text-sm ${
                  selectedBrand === '' ? 'text-gray-400' : 'text-gray-600'
                } `}
                htmlFor="brand"
              >
                Select Model
              </label>
            </div>
            <div>
              <select
                onChange={(e) => {
                  setSelectedModel(e.target.value);
                }}
                className="{`w-full p-2 mt-2 placeholder-gray-400 border focus:ring-slate-500 focus:border-slate-500  rounded-md shadow-sm focus:outline-none border-gray-300 text-sm disabled:opacity-50"
                name="brand"
                id="brand"
                disabled={selectedBrand === ''}
              >
                <option value="">Select Model</option>
                {models &&
                  models.map(({ model }, index) => (
                    <option key={index} value={model}>
                      {model}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <button
            onClick={() => {
              setSelectedBrand('');
              setSelectedModel('');
            }}
            className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Clear Filter
          </button>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="inline-block min-w-full">
            <div className="overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                    >
                      Brand
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                    >
                      Model
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                    >
                      Body Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                    >
                      Model Year
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                    >
                      Regional Specs
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                    >
                      Doors
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                    >
                      Kilometers
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                    >
                      Transmission Type
                    </th>
                    {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                    >
                      Steering Side
                    </th> */}
                    {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                    >
                      Number of Cylinders
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                    >
                      Engine Capacity (CC)
                    </th> */}
                    {/* <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Expand</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {products &&
                    products.map((product, productIndex) => (
                      <React.Fragment key={`${product.id}-${productIndex}`}>
                        <tr
                          className={`cursor-zoom-in hover:bg-green-50 ${
                            productIndex % 2 === 0 ? undefined : 'bg-gray-50'
                          }`}
                          onClick={() => toggleExpandedRow(productIndex)}
                        >
                          <td className=" py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {product.name}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {product.brand}
                          </td>
                          <td className="py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {product.model}
                          </td>
                          <td className="py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {product.body_type}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {product.model_year}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {product.regional_specs}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {product.doors}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {product.price}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {product.kilometers}
                          </td>
                          <td className="py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {product.transmission_type}
                          </td>
                          {/* <td className="py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {product.steering_side}
                          </td> */}
                          {/* <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {product.number_of_cylinders}
                          </td>
                          <td className="py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {product.engine_capacity_cc}
                          </td> */}
                          {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
                            <button
                              type="button"
                              onClick={() => toggleExpandedRow(productIndex)}
                              className="text-blue-600 hover:text-blue-900 ml-3"
                            >
                              {expandedRows.includes(productIndex)
                                ? 'Collapse'
                                : 'Expand'}
                            </button>
                          </td> */}
                        </tr>

                        {expandedRows.includes(productIndex) && (
                          <tr>
                            <td colSpan="13" className="px-6 py-4">
                              <div>
                                {product.target_market && (
                                  <p className="text-left text-xs font-medium">
                                    <strong>Interior Color:</strong>{' '}
                                    {product.target_market}
                                  </p>
                                )}
                                {product.number_of_cylinders && (
                                  <p className="text-left text-xs font-medium">
                                    <strong>number Of Cylinders:</strong>{' '}
                                    {product.number_of_cylinders}
                                  </p>
                                )}
                                {product.engine_capacity_cc && (
                                  <p className="text-left text-xs font-medium">
                                    <strong>Engine Capacity Cc:</strong>{' '}
                                    {product.engine_capacity_cc}
                                  </p>
                                )}
                                {product.interior_color && (
                                  <p className="text-left text-xs font-medium">
                                    <strong>Interior Color:</strong>{' '}
                                    {product.interior_color}
                                  </p>
                                )}
                                {product.steering_side && (
                                  <p className="text-left text-xs font-medium">
                                    <strong>Steering Side:</strong>{' '}
                                    {product.steering_side}
                                  </p>
                                )}
                                {product.exterior_color && (
                                  <p className="text-left text-xs font-medium">
                                    <strong>Exterior Color:</strong>{' '}
                                    {product.exterior_color}
                                  </p>
                                )}
                                {product.horsepower && (
                                  <p className="text-left text-xs font-medium">
                                    <strong>Horsepower:</strong>{' '}
                                    {product.horsepower}
                                  </p>
                                )}
                                {product.location && (
                                  <p className="text-left text-xs font-medium">
                                    <strong>Location:</strong>{' '}
                                    {product.location}
                                  </p>
                                )}
                                {product.fuel_type && (
                                  <p className="text-left text-xs font-medium">
                                    <strong>Fuel Type:</strong>{' '}
                                    {product.fuel_type}
                                  </p>
                                )}
                                {product.warranty && (
                                  <p className="text-left text-xs font-medium">
                                    <strong>Warranty:</strong>{' '}
                                    {product.warranty}
                                  </p>
                                )}
                                {product.seller_type && (
                                  <p className="text-left text-xs font-medium">
                                    <strong>Seller Type:</strong>{' '}
                                    {product.seller_type}
                                  </p>
                                )}
                                {product.descriptions && (
                                  <p className="text-left text-xs font-medium">
                                    <strong>Seller Type:</strong>{' '}
                                    {product.descriptions}
                                  </p>
                                )}
                                {product.motors_trim && (
                                  <p className="text-left text-xs font-medium">
                                    <strong>Seller Type:</strong>{' '}
                                    {product.motors_trim}
                                  </p>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
              {selectedBrand === '' && (
                <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-b md:rounded-bt-lg border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="flex-1 flex justify-between sm:justify-end">
                    <button
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      disabled={page === 1}
                      onClick={goToPreviousPage}
                    >
                      Previous
                    </button>
                    <button
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      disabled={!hasMore}
                      onClick={goToNextPage}
                    >
                      Next
                    </button>
                  </div>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Products = () => {
  return (
    <DashboardLayout pageTitle="Products">
      <ProductsList />
    </DashboardLayout>
  );
};

export default Products;
