import React from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Statistics from './pages/statistics';
import Products from './pages/products';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Statistics />,
  },
  {
    path: '/products',
    element: <Products />,
  },
  {
    path: '*', // Catch-all route
    element: <div>Not found</div>, // Component for handling 404s
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
