import { useQuery } from 'react-query';
import { supabase } from '../service/supabase';

async function getAllProducts(page, selectedBrand, selectedModel) {
  let products = [];
  if (selectedBrand === '') {
    const start = (page - 1) * 500;
    const end = page * 500 - 1;
    const { data, error } = await supabase
      .from('products')
      .select('*')
      .range(start, end);

    if (error) {
      throw error;
    }
    if (data) {
      products = data;
    }
    return products;
  } else {
    const { data, error } = await supabase.rpc('get_filtered_products', {
      filter_brand: selectedBrand || null,
      filter_model: selectedModel || null,
      // page_size: 500,
      // page_number: page,
    });
    if (error) {
      throw error;
    }
    if (data) {
      products = data;
    }
    return products;
  }
}

async function getUniqueBrands() {
  try {
    const { data, error } = await supabase.rpc('get_unique_brands');
    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.log('error :', error);
  }
}

async function getModelsForBrand(brandName) {
  try {
    if (!brandName) {
      return [];
    }

    const { data, error } = await supabase.rpc('get_unique_models', {
      brand_name: brandName,
    });

    if (error) {
      throw error;
    }
    return data;
  } catch (error) {
    console.log('error :', error);
  }
}

export function useProducts(page, selectedBrand = '', selectedModel = '') {
  return useQuery(['products', page, selectedBrand, selectedModel], () =>
    getAllProducts(page, selectedBrand, selectedModel)
  );
}

export function useBrandsList() {
  return useQuery(['uniqBrands'], () => getUniqueBrands());
}

export function useUniqModelsList(brandName = null) {
  return useQuery(['uniqModels', brandName], () =>
    getModelsForBrand(brandName)
  );
}
